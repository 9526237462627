@import './../../constants.scss';
#App{
    width: 100%;
    overflow: hidden;
    
    .container{
        width: 1000px;
        margin: 0 auto;
    }
    @media only screen and (max-width: 1080px) {
        .container{
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;
        }
    }
    @media only screen and (max-width: 420px) {
        .container{
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    section{
        width: 100%;
        background-size: cover !important;
        background-position: center !important;
    }

    .Banner{
        background: url(./../../images/Banner/background.jpg);
        padding: 80px 0;
    }

    input[type="text"], input[type="email"]{
        width: 100%;
        font-size: 15px;
        box-sizing: border-box;
        border: 2px solid $magenta;
        border-radius: $borderRadius;
        padding: 10px 10px;
        background: rgba($magenta, .5);
        color: white;
        &::placeholder{
            color: rgba(white, .5);
        }
        font-weight: bold;
    }
}
.hoverZoom{
    transform-origin: center;
    transform: scale(1);
    transition: $buttonsTransition;

    &:hover{
        transform: scale(1.1);
    }
}
.gradientBordersBanner{
    border-style:  solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, hsl(239, 81%, 44%), $magenta);
    padding: 30px 60px;
    background: rgba(black, .5);
    font-family: "Barlow";
    font-weight: 100;
    font-size: 25px;
    box-shadow: 0 0 30px rgba(cyan, .2);    
    border-left: none;
    border-right: none;
    margin-bottom: 40px;
}
@media only screen and (max-width: 720px) {
    .gradientBordersBanner{
        font-size: 18px;
        padding: 20px;
    }
}
@media only screen and (max-width: 595px) {
    .gradientBordersBanner{
        padding: 20px 30px;
    }
}
@media only screen and (max-width: 510px) {
    .gradientBordersBanner{
        padding: 20px 50px;
    }
}
@media only screen and (max-width: 450px) {
    .gradientBordersBanner{
        padding: 20px 30px;
    }
}
@media only screen and (max-width: 420px) {
    .gradientBordersBanner{
        font-size: 18px;
        padding: 15px 25px;
    }
}

.textAlignCenter{
    text-align: center;
}