@import './../../../constants.scss';

#Header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    color: white;
    transition: all 1s ease-in-out;

    &.inactive{
        top: -100%;
    }
    &.active{
        top: 0%;
    }

    .background{
        position: absolute;
        width: 100%;
        height: 150%;
        z-index: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 80%);
    }

    .content{
        display: flex;
        justify-content: flex-end;
        padding: 30px;
        position: relative;
        z-index: 1;

        .logoBar{
            position: fixed;
            left: 40px;
            top: 36px;
            width: 25px;
            height: 25px;
            align-items: center;

            img{
                width: 180px;
                height: auto;
            }
        }

        .menuBar{
            display: flex;
            align-items: center;
            font-size: 20px;
            
            .SocialLinks{
                margin-right: 70px;
                margin-top: 6px;
            }

            @media only screen and (max-width: 450px) {
                .SocialLinks{
                    display: none;
                }
            }

            .menuBtn{
                position: fixed;
                right: 40px;
                top: 36px;
                width: 25px;
                height: 25px;

                button{
                    transition: $MenuTransition;
                    background: none;
                    border: none;
                    color: white;
                    font-size: 20px;
                    position: absolute;
                }

                &.activeMenu{
                    .inactiveMenuButton{
                        opacity: 1;
                        transform: rotate(360deg);
                    }
                    .activeMenuButton{
                        opacity: 0;
                        transform: rotate(180deg);
                    }
                    
                }
                &.inactiveMenu{
                    .inactiveMenuButton{
                        opacity: 0;
                        transform: rotate(180deg);
                    }
                    .activeMenuButton{
                        opacity: 1;
                        transform: rotate(0deg);
                    }

                }
            }
        }
    }
} 