@import 'constants.scss';

#Overall{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;

    &.menuActive, &.videoActive, &.popUpActive{
        pointer-events: all;

        .background{
            opacity:1;
            cursor: pointer;
        }
    }

    &.menuActive{
        .Menu{
            top: 0%;
        }
    }
    .background{
        transition: $MenuLongTransition;
        opacity:0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(black, .8);
        box-shadow: inset 0px 0px 200px rgba(black, .5);
    }
    @import 'node_modules/react-modal-video/scss/modal-video.scss';
    .modal-video{
        z-index: 2;
    }
    .PopUp {
        position: absolute;
        background: white;
        width: 80%;
        max-width: 600px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px black;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        box-sizing: border-box;
        max-height: 90vh;
        overflow-y: scroll;
        
        h1, h3{
            color: $magenta;
        }
        ul, li{
            color: $darkText;
        }
    }
    .Menu{
        transition: $MenuTransition;
        position: absolute;
        z-index: 1;
        width: auto;
        right: 0;
        top: -100%;
        background: rgba(black, .8);
        padding: 100px 35px 50px 35px;
        border-bottom-left-radius: 30px;
        border: 2px solid rgba($magenta, .6);
        box-shadow: 0px 0px 20px rgba(black, .5);
        border-right: none;
        border-top: none;

        ul{
            align-items: flex-end;
            border-top: 1px solid rgba(white, .5);
            padding-top: 40px ;
            padding-bottom: 40px ;
            min-width: 160px;
        }
        .SocialLinks {
            ul{
                padding-bottom: 0;
                justify-content: flex-end;
            }
        }
    }
}