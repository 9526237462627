// COLORS
$magenta: #f41f55;
$cyan: #59f3ff;
$darkText: #444;

// GRADIENTS
$microcontrollerGradient: linear-gradient(90deg, rgba(0,151,157,1) 0%, rgba(6,133,155,1) 45%, rgba(30,67,148,1) 100%);
$mercury10Gradient: linear-gradient(90deg, rgba(237,29,86,1) 0%, rgba(18,5,78,1) 100%);
$microcomputerGradient: linear-gradient(90deg, rgba(106,191,75,1) 0%, rgba(131,145,75,1) 45%, rgba(195,28,74,1) 100%);

// TRANSITIONS
$buttonsTransition: all 0.2s ease-in-out;
$SliderSlidesTransition: all 0.4s ease-in-out;
$SliderSlidesLongTransition: all 0.8s ease-in-out;
$MenuTransition: all 0.4s ease-in-out;
$MenuLongTransition: all 0.8s ease-in-out;

// BORDER RADIUS
$borderRadius: 5px;