@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;700&family=Rubik:wght@400;600&display=swap');

html{
    scroll-behavior: smooth;
    background: -webkit-linear-gradient(left, #321d4d, #350C44,#222358);
}

body {
    margin: 0;
    font-family: 'Rubik';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: -webkit-linear-gradient(left, #321d4d, #350C44,#222358);
}

*{
    outline: none;
}

a{
    color: inherit;
}

a, button{
    cursor: pointer;
}