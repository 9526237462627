.SocialLinks{
    font-size: 20px;
    display: inline;
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-flex;
    }
    li{
        margin-right: 20px;

        &:last-child{
            margin-right: 0 !important;
        }
    }
}