@import 'constants.scss';

#Home{
    position: relative;
    overflow: hidden;

    & > .Background{
        width: 100%;
        background: rgb(103,59,157);
        background: radial-gradient(at 50% 0%, rgba(103,59,157,1) 0%, rgba(18,5,78,1) 100%);
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;

        .floatingObject{
            position: absolute;
        }

        .spot1{
            width: 115%;
            height: 40%;
            left: 30%;
            top: 0%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                at 20% 50%,
                rgba(0,0,0,1) 0%,
                rgba(0,0,0,0) 70%
            );
            opacity: .6;
            mix-blend-mode: multiply;
        }

        .spot2{
            width: 50%;
            height: 60%;
            left: 0%;
            top: 65%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                at 50% 50%,
                rgba(244,31,85,1) 0%,
                rgba(244,31,85,0) 70%
            );
            opacity: .2;
        }

        .spot3{
            width: 65%;
            height: 80%;
            left: 100%;
            top: 35%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                at 50% 50%,
                rgba(89,243,255,1) 0%,
                rgba(89,243,255,0) 70%
            );
            opacity: .2;
        }

        .spot4{
            width: 80%;
            height: 80%;
            left: 0%;
            top: 100%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                at 20% 50%,
                rgba(0,0,0,1) 0%,
                rgba(0,0,0,0) 70%
            );
            opacity: .75;
            mix-blend-mode: multiply;
        }

        .spot5{
            width: 80%;
            height: 90%;
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                circle,
                rgba(0,0,100,1) 0%,
                rgba(0,0,100,0) 70%
            );
            opacity: .6;
            // mix-blend-mode: multiply;
        }

        .spot6{
            width: 80%;
            height: 80%;
            left: 100%;
            top: 100%;
            transform: translate(-50%, -50%);
            background: radial-gradient(
                at 50% 50%,
                rgba(0,0,0,1) 0%,
                rgba(0,0,0,0) 70%
            );
            opacity: .95;
            mix-blend-mode: multiply;
        }

        .texture{
            width: 100%;
            height: 100%;
            background: url(./assets/background.jpg);
            opacity: .5;
            mix-blend-mode: hard-light;
        }
    }

    .Content{
        position: relative;
        z-index: 1;
        text-align: center;
        padding-bottom: 70px;

        .cover {
            width: 100vw;
            height: 100vh;
            max-height: 750px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 36px 0 30px;
            box-sizing: border-box;
            align-items: center;
            margin-bottom: 30px;
            
            .text{
                font-family: "Barlow";
            }
            .text-1{
                font-size: 20px;
            }
            @media only screen and (max-width: 470px) {
                .text-1{
                    b{
                        display: block;
                    }
                    
                }
            }
            .isologo{
                width: 360px;
                height: auto;
                display: inline-block;

                
                img{
                    width:100%;
                    height: auto;
                }
            }
            @media only screen and (max-height: 610px) {
                .isologo{
                    width: 300px;
                }
            }
            @media only screen and (max-width: 450px) {
                .isologo{
                    width: 85%;
                }
            }
            .text-2{
                font-size: 25px;
                font-weight: 500;
            }
            @media only screen and (max-width: 540px) {
                .text-2{
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            /*
            .Slider{
                width: 400px;
                height: 350px;
                margin: 0 auto;
                margin-bottom: 10px;
                position: relative;

                
    
                .Background{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
    
                    .hexagon-base{
                        background: url(./assets/Slider/hexagon-base.png);
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: relative;
                    }
                    .hexagon-base-glow{
                        position: absolute;
                        width: 125%;
                        height: 125%;
                        top: 50%;
                        left: 50%;
                        background: url(./assets/Slider/hexagon-base-glow.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        animation-duration: 4s;
                        animation-iteration-count: infinite;
                    }
                    .hexagon-base-glow-1{
                        transform: translate(-50%, -50%);
                        animation-name: _hexagon-base-glow-1;
                    }
                    @keyframes _hexagon-base-glow-1{
                        0% { opacity: 0; }
                        25% {width: 130%;
                            height: 130%;}
                        50% { opacity: 1; }
                        75% {width: 115%;
                            height: 115%;}
                        100% { opacity: 0; }
                    }
                    .hexagon-base-glow-2{
                        transform: translate(-50%, -50%) rotate(180deg);
                        animation-name: _hexagon-base-glow-2;
                    }
                    @keyframes _hexagon-base-glow-2{
                        0% { opacity: 1; }
                        25% {width: 115%;
                            height: 115%;}
                        50% { opacity: 0; }
                        75% {width: 130%;
                            height: 130%;}
                        100% { opacity: 1; }
                    }
                }
                .Slides{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transition: $SliderSlidesTransition;
    
                    .Slide{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 0.4s ease-in-out;
                        display: none;
                        pointer-events: none;

                        .mobileTitle{
                            display: none;
                        }
                        .TagImage{
                            position: absolute;
                            left: -60px;
                            bottom: -10px;
                            margin: -40px;
                            

                            .mainImage{
                                position: relative;
                                z-index: 1;
                                width: 90%;
                                transition: all 0.4s ease-in-out;
                                bottom: -100px;
                                opacity: 0;
                                transform-origin: center;
                                transform: rotate(-180deg);

                                img{
                                    width: 100%;
                                }
                            }

                            .decorator{
                                position: absolute;
                                width: 150px;
                                top:-350px;
                                right:-30px;
                                transition: all 0.3s ease-in-out;
                                transform-origin: center;
                                opacity: 0;
                                transform-origin: center;
                                transform: rotate(180deg);

                                img{
                                    width: auto;
                                }
                            }
                        }
                        .buttonZone{
                            position: absolute;
                            left: 79%;
                            top: 80%;
                            opacity: 0;
                            transition: all 0.4s ease-in-out;
                            transform: rotate(-180deg) scale(0);

                            .diagonal{
                                position: absolute;
                                background: url(./assets/Slider/diagonal-2.png);
                                background-repeat: no-repeat;
                                background-size: cover;
                                width: 26px;
                                height: 44px;
                                bottom: 95%;
                                left: -13px;
                            }
                        }
                    }
                    .Slide.active{
                        display: block;
                        left:50%;
                        opacity: 1;
                        pointer-events: all;

                        .TagImage{
                            .mainImage{
                                bottom: 0px;
                                opacity: 1;
                                transform: rotate(0deg);
                                left: 20px;
                                bottom: 30px;
                            }
                            .decorator{
                                top:-50px;
                                opacity: 1;
                                transform: rotate(0deg);
                            }
                        }
                        .buttonZone{
                            opacity: 1;
                            transition: all 0.3s ease-in-out;
                            transform: rotate(0deg);
                        }
                    }
                    .Slide.leftDisplace{
                        display: block;
                        left:-50%;
                        opacity: 0;
                    }
                    .Slide.rightDisplace{
                        display: block;
                        left:150%;
                        opacity: 0;
                    }
                }
                .Control{
                    position: absolute;
                    left: -21%;
                    top: 0px;
                    width: 100%;

                    .SliderButtons{
                        left: 15px;
                        top: 2px;
                    }
                    .diagonal{
                        width: 40px;
                        position: absolute;
                        top: 56px;
                        left: 92px;
                    }
                    .SliderMobileControl{
                        display: none;
                        position: absolute;
                        width: 140%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .SliderCounter{
                    left: 100%;
                    top: 0;
                    position: absolute;
                }
            }*/
            @media only screen and (max-width: 850px) {
                .Slider{
                    transform: scale(.8);
                    margin-top: -4%;
                    margin-bottom: 0%;
                }
            }
            @media only screen and (max-width: 610px) {
                .Slider{
                    transform: scale(.7);
                    margin-bottom: 2%;
                }
            }
            @media only screen and (max-width: 540px) {
                .Slider{
                    transform: scale(.7);
                    top: -5%;

                    .SliderCounter{
                        display: none;
                    }

                    .Control{
                        left: 0;
                        top: 50%;
                        .SliderButtons, .diagonal{
                            display: none;
                        }
                        .SliderMobileControl{
                            display: flex;
                        }
                    }
                    .Slides{
                        .Slide{
                            .mobileTitle {
                                display: block;
                                position: absolute;
                                bottom: 4%;
                                left: 50%;
                                transform: translateX(-50%);
                                padding: 6px 30px;
                                font-size: 20px;
                                background: -moz-linear-gradient(90deg, rgba(244,31,85,0) 0%, rgba(244,31,85,1) 15%, rgba(244,31,85,1) 85%, rgba(244,31,85,0) 100%);
                                background: -webkit-linear-gradient(90deg, rgba(244,31,85,0) 0%, rgba(244,31,85,1) 15%, rgba(244,31,85,1) 85%, rgba(244,31,85,0) 100%);
                                background: linear-gradient(90deg, rgba(244,31,85,0) 0%, rgba(244,31,85,1) 15%, rgba(244,31,85,1) 85%, rgba(244,31,85,0) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f41f55",endColorstr="#f41f55",GradientType=1);
                            }
                            .TagImage{
                                display: none;
                            }
                            .buttonZone{
                                left: 50%;
                                top: auto;
                                bottom: -15%;
                                transform: rotate(0deg) translateX(-50%) !important;
                            }
                        }
                        .Slide.active{
                            .buttonZone{
                                .diagonal{
                                    display: none;
                                }
                            }
                        }
                    } 
                }
            }
            @media only screen and (max-width: 430px) {
                .Slider{
                    .Control{
                        
                    }
                }
            }
            @media only screen and (max-width: 400px) {
                .Slider{
                    transform: scale(.6);
                    margin: -15%;
                    margin-bottom: 2%;
                }
            }
            @media only screen and (max-width: 360px) {
                .Slider{
                    .Control{
                        .SliderMobileControl{
                            width: 130%;
                        }
                    }
                }
            }
        }
        @media only screen and (max-height: 600px) {
            .cover {
                height: auto;
                row-gap: 20px;
            }
        }
        
    }
}