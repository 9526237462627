@import 'constants.scss';

.ContentV2{
    position: relative;
    z-index: 1;
    text-align: center;
    padding-bottom: 70px;

    .comingSoon{
        flex-direction: column;
        display: flex;
        

        &.onHeader{
            display: flex;
            position: absolute;
            right: 100px;
            top: 20px;
            align-items: flex-end;
        }
        &.onBody{
            display: none;
        }
        @media only screen and (max-width: 680px) {
            &.onHeader{
                display: none;
            }
            &.onBody{
                display: block;
                margin-top: 20px;
            }
        }


        
        .text{
            font-size: 20px;
            font-family: "Barlow";
            font-style: italic;
        }
        .image{
            img{
                height: 26px;
                width: auto;
            }
        }
    }
    .cover {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        padding: 100px 0 30px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 30px;

        & > .header{
            margin-bottom: 40px;
            @media only screen and (max-width: 768px) {
                margin-bottom: 0;
            }

            .title{
                font-size: 56px;
                b{
                    color: $magenta;
                }
            }
            .subtitle{
                font-size: 28px;
                letter-spacing: 2px;
            }
            @media only screen and (max-width: 630px) {
                .title{
                    font-size: 40px;
                }
                .subtitle{
                    font-size: 20px;
                }
            }
        }

        .columns {
            width: 100%;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 920px) {
                align-items: flex-start;
            }
            @media only screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
            
            .column:first-child{
                margin-right: 30px;
                width: calc( 45% - 30px);
                @media only screen and (max-width: 920px) {
                    width: calc( 50% - 30px)
                }
                @media only screen and (max-width: 768px) {
                    margin: 0;
                    margin-bottom: 30px;
                    width: 100%;
                    max-width: 450px;
                }
            }
            .column:last-child{
                width: calc( 55% - 50px);
                padding-right: 50px;

                @media only screen and (max-width: 920px) {
                    width: calc( 50% - 50px);
                }
                @media only screen and (max-width: 768px) {
                    margin: 0;
                    width: 100%;
                    padding: 0;
                }


                .KeyFeatures{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 20px;
                    margin-bottom: 60px;
                    

                    @media only screen and (max-width: 768px) {
                        justify-content: center !important;
                    }
                    @media only screen and (max-width: 485px) {
                        flex-direction: column;
                        padding: 0px 50px;
                        text-align: left;
                    }
                    @media only screen and (max-width: 380px) {
                        padding: 0px 30px;
                    }

                    .KeyFeature {
                        flex: 1;
                        
                        @media only screen and (max-width: 485px) {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;

                            br{
                                display: none;
                            }
                        }
                        .title{
                            font-family: 'Rubik';
                            color:$cyan;
                            font-weight: 600;
                            font-size: 20px;
                            margin-bottom: 5px;
                        }
                        .text{
                            font-size: 14px;
                        }

                        .Image{
                            @media only screen and (max-width: 485px) {
                                margin-right: 20px;
                            }
                            img{
                                width: 80px;
                            }
                        }
                    }
                }
                @media only screen and (max-width: 920px) {
                    .KeyFeatures{
                        display: flex;
                        justify-content: space-between;
                        padding-top: 20px;
                        margin-bottom: 40px;
    
                        .KeyFeature {
                            flex: 1;
    
                            .title{
                                font-family: 'Rubik';
                                color:$cyan;
                                font-weight: 600;
                                font-size: 16px;
                                margin-bottom: 5px;
                            }
                            .text{
                                font-size: 13px;
                            }
                        }
                    }
                }
                .signUpForm{
                    @media only screen and (max-width: 768px) {
                        padding: 0px 20px;
                    }
                    .header{
                        margin-bottom: 10px;
                        .title{
                            font-size: 24px;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }
                        .subtitle{
                            letter-spacing: 1px;
                        }
                    }
                    .MailchimpSimpleForm{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;

                        input[type="text"], input[type="email"]{
                            border-right: none !important;
                            border-bottom-right-radius: 0 !important;
                            border-top-right-radius: 0 !important;
                            flex: 0 0 calc(70% - 20px);
                            @media only screen and (max-width: 380px) {
                                flex: 0 0 calc(70% - 40px);
                            }
                        }
                        button{
                            flex: 0 0 25%;
                            background: $magenta;
                            border: 0;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 16px;
                            padding-left: 15px;
                            padding-right: 15px;
                            border-bottom-right-radius: 5px !important;
                            border-top-right-radius: 5px !important;
                            border: 2px solid rgba(black, .2);
                            border-left: none;
                            font-family: "Barlow";
                            text-transform: uppercase;
                            font-weight: bold;
                            .icon{
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }
        }
        
    }
    @media only screen and (max-height: 600px) {
        .cover {
            height: auto;
            row-gap: 20px;
        }
    }  
}