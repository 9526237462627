@import './../../../constants.scss';
.SliderButtons{
    position: relative;
    width: 100px;
    height: 80px;

    button{
        font-size: 22px;
        line-height: 1em;
        border: none;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
        position: absolute;
        height: 40px;
        color: white;
        opacity: .85;
        transition: $buttonsTransition;
        transform-origin: center;
        transform: scale(1);

        &:hover{
            opacity: 1;
            transform: scale(1.1);
        }

        &.previous{
            background: url(./assets/previous.png);
            padding: 8px 22px 6px 18px;
            z-index: 1;
            top: 34px;
            left: -7px;
            color: white;
            height: 47px;
        }
        &.next{
            background: url(./assets/next.png);
            padding: 10px 26px 6px 29px;
            left: 35px;
            height: 60px;
        }
    }
}