@import 'constants.scss';
.Button{
    display: inline-block;
    opacity: .9;
    transform-origin: center;
    transition: $buttonsTransition;
    
    &:hover{
        opacity: 1;
        transform:scale(1.1);
    }
    .body{
        background-repeat: repeat-x;
        cursor: pointer;
        background: url(./assets/body.png);
        background-size: contain;
        color: white;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 10px 20px;
    }
}