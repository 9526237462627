@import 'constants.scss';

.SliderMobileControl{
    display: flex;
    justify-content: space-between;

    button{
        background: none;
        border: none;
        color: white;
        font-size: 50px;
    }
}