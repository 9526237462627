@import './../../../constants.scss';

.SectionsLinks{

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        li{
            a{
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }

    &.row{
        ul{
            flex-direction: row;
            li{
                margin-right: 5px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    &.column{
        ul{
            flex-direction: column;
            li{
                margin-bottom: 5px;
                
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}