@import 'constants.scss';

.HexagonSlider{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    position: relative;
    padding: 0px 20px;
    box-sizing: border-box;

    .wrapper{
        position: relative;
        padding-bottom: 87.5%;
        width: 100%;

        .Background{
            width: 100%;
            height: 100%;
            pointer-events: none;
            position: absolute;

            .hexagon-base{
                background: url(./assets/hexagon-base.png);
                width: 100%;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 91.5%;
                z-index: 1000;
                
            }
            .hexagon-base-glow{
                position: absolute;
                width: 125%;
                height: 125%;
                top: 50%;
                left: 50%;
                background: url(./assets/hexagon-base-glow.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                animation-duration: 4s;
                animation-iteration-count: infinite;
            }
            .hexagon-base-glow-1{
                transform: translate(-50%, -50%);
                animation-name: _hexagon-base-glow-1;
            }
            @keyframes _hexagon-base-glow-1{
                0% { opacity: 0; }
                25% {width: 130%;
                    height: 130%;}
                50% { opacity: 1; }
                75% {width: 115%;
                    height: 115%;}
                100% { opacity: 0; }
            }
            .hexagon-base-glow-2{
                transform: translate(-50%, -50%) rotate(180deg);
                animation-name: _hexagon-base-glow-2;
            }
            @keyframes _hexagon-base-glow-2{
                0% { opacity: 1; }
                25% {width: 115%;
                    height: 115%;}
                50% { opacity: 0; }
                75% {width: 130%;
                    height: 130%;}
                100% { opacity: 1; }
            }
        }
        .Slides{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: $SliderSlidesTransition;

            .Slide{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.4s ease-in-out;
                display: none;
                pointer-events: none;

                .mobileTitle{
                    display: none;
                }
                .Image{
                    width: 100%;
                    height: 100%;

                    img{
                        width: 133%;
                        height: auto;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .TagImage{
                    position: absolute;
                    left: -60px;
                    bottom: -10px;
                    margin: -40px;
                    

                    .mainImage{
                        position: relative;
                        z-index: 1;
                        width: 90%;
                        transition: all 0.4s ease-in-out;
                        bottom: -100px;
                        opacity: 0;
                        transform-origin: center;
                        transform: rotate(-180deg);

                        img{
                            width: 100%;
                        }
                    }

                    .decorator{
                        position: absolute;
                        width: 150px;
                        top:-350px;
                        right:-30px;
                        transition: all 0.3s ease-in-out;
                        transform-origin: center;
                        opacity: 0;
                        transform-origin: center;
                        transform: rotate(180deg);

                        img{
                            width: auto;
                        }
                    }
                }
                .buttonZone{
                    position: absolute;
                    left: 79%;
                    top: 80%;
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
                    transform: rotate(-180deg) scale(0);

                    .diagonal{
                        position: absolute;
                        background: url(./assets/diagonal-2.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 26px;
                        height: 44px;
                        bottom: 95%;
                        left: -13px;
                    }
                }
            }
            .Slide.active{
                display: block;
                left:50%;
                opacity: 1;
                pointer-events: all;

                .TagImage{
                    .mainImage{
                        bottom: 0px;
                        opacity: 1;
                        transform: rotate(0deg);
                        left: 20px;
                        bottom: 30px;
                    }
                    .decorator{
                        top:-50px;
                        opacity: 1;
                        transform: rotate(0deg);
                    }
                }
                .buttonZone{
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(0deg);
                }
            }
            .Slide.leftDisplace{
                display: block;
                left:-50%;
                opacity: 0;
            }
            .Slide.rightDisplace{
                display: block;
                left:150%;
                opacity: 0;
            }
        }
        .Control{
            position: absolute;
            transform: scale(.8);
            
            top: 0px;
            // width: 100%;

            .SliderButtons{
                position: static;
                left: 15px;
                top: 2px;
            }
            .diagonal{
                width: 40px;
                position: absolute;
                top: 56px;
                left: 92px;
            }
            .SliderMobileControl{
                display: none;
                position: absolute;
                width: 140%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .SliderCounter{
            left: 100%;
            top: 0;
            position: absolute;
        }
    }
    // width: 400px;
    // height: 350px;
}